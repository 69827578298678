<template>
  <v-list>
    <v-list-item v-for="examen in examenes" :key="examen._id">
      <v-list-item-avatar>
        <v-avatar color="primary">
          <v-icon dark>mdi-text-box-check</v-icon>
        </v-avatar>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          <router-link :to="`examenes/${examen._id}`" class="link"
            >{{ examen.nombre }}
          </router-link>
        </v-list-item-title>
        <v-list-item-subtitle
          >Creado:
          {{ getLocaleString(examen.creador.fecha) }}</v-list-item-subtitle
        >
      </v-list-item-content>
      <v-list-item-action v-if="esAdmin">
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(menuExamenItem, index) in menuExamen"
              :key="index"
              @click="menuAction({ ...menuExamenItem, id: examen._id })"
            >
              <v-list-item-title>
                <v-icon small class="mr-2">{{ menuExamenItem.icon }}</v-icon>
                <span>{{ menuExamenItem.text }}</span>
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="app === 'campus'"
              @click="mostrarEliminacionExamen(examen)"
            >
              <v-list-item-title>
                <v-icon small class="mr-2">mdi-delete</v-icon>
                <span>Eliminar examen</span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item-action>
    </v-list-item>

    <crear-examen-dialog
      v-if="mostrarCreacionExamen"
      :mostrar="mostrarCreacionExamen"
      :examen="examenParaModificar"
      @cancelar="cerrarCreacionExamen"
      @examenGuardado="examenGuardado"
    />

    <mover-area-dialog
      v-if="mostrarMoverArea"
      :mostrar="mostrarMoverArea"
      :areas="areas"
      :examen="examenParaModificar"
      @cancelar="cerrarMoverArea"
      @examenMovido="examenMovido"
    />

    <clonar-examen-dialog
      v-if="mostrarClonarExamen"
      :mostrar="mostrarClonarExamen"
      :areas="areas"
      :examen="examenParaModificar"
      @cancelar="cerrarClonarExamen"
      @examenClonado="examenClonado"
    />

    <eliminar-examen-dialog
      v-if="mostrarEliminarExamen"
      :mostrar="mostrarEliminarExamen"
      :idExamen="examenParaModificar._id"
      @cancelar="cerrarEliminarExamen()"
      @examenEliminado="examenEliminado"
    ></eliminar-examen-dialog>
  </v-list>
</template>

<script>
import { mapGetters } from "vuex";
import { DateTime } from "luxon";

export default {
  props: {
    examenes: { type: Array, default: () => [] },
    areas: { type: Array, default: () => [] },
  },

  components: {
    "crear-examen-dialog": () => import("./crearExamenDialog.vue"),
    "mover-area-dialog": () => import("./moverAreaExamenDialog.vue"),
    "clonar-examen-dialog": () => import("./clonarExamenDialog.vue"),
    "eliminar-examen-dialog": () => import("./confirmarEliminacionExamen.vue"),
  },

  data: () => ({
    menuExamen: [
      {
        text: "Modificar examen",
        action: "modificarExamen",
        icon: "mdi-pencil",
      },
      {
        text: "Mover de área",
        action: "moverDeArea",
        icon: "mdi-subdirectory-arrow-left",
      },
      {
        text: "Duplicar examen",
        action: "clonarExamen",
        icon: "mdi-content-copy",
      },
    ],
    mostrarCreacionExamen: false,
    examenParaModificar: null,
    mostrarMoverArea: false,
    mostrarClonarExamen: false,
    mostrarEliminarExamen: false,
  }),

  computed: {
    ...mapGetters(["app", "role"]),

    esAdmin() {
      return this.app === "campus" || this.role === "admin";
    },
  },

  methods: {
    menuAction(data) {
      switch (data.action) {
        case "modificarExamen":
          this.examenParaModificar = this.examenes.filter(
            (e) => e._id === data.id
          )[0];
          this.mostrarCreacionExamen = true;
          break;
        case "moverDeArea":
          this.examenParaModificar = this.examenes.filter(
            (e) => e._id === data.id
          )[0];
          this.mostrarMoverArea = true;
          break;
        case "clonarExamen":
          this.examenParaModificar = this.examenes.filter(
            (e) => e._id === data.id
          )[0];
          this.mostrarClonarExamen = true;
          break;
      }
    },

    getLocaleString(fecha) {
      const date = DateTime.fromISO(fecha)
        .setLocale("es")
        .toLocaleString(DateTime.DATE_FULL);
      return date;
    },

    cerrarCreacionExamen() {
      this.mostrarCreacionExamen = false;
      this.examenParaModificar = null;
    },

    examenGuardado(examen) {
      this.cerrarCreacionExamen();
      this.$emit("examenGuardado", examen);
    },

    cerrarMoverArea() {
      this.mostrarMoverArea = false;
      this.examenParaModificar = null;
    },

    examenMovido(examen) {
      this.mostrarMoverArea = false;
      this.examenParaModificar = null;

      this.$emit("examenMovido", examen);
    },

    cerrarClonarExamen() {
      this.mostrarClonarExamen = false;
      this.examenParaModificar = null;
    },

    examenClonado(examen) {
      this.mostrarClonarExamen = false;
      this.examenParaModificar = null;

      this.$emit("examenClonado", examen);
    },

    mostrarEliminacionExamen(examen) {
      this.examenParaModificar = examen;
      this.mostrarEliminarExamen = true;
    },

    cerrarEliminarExamen() {
      this.mostrarEliminarExamen = false;
      this.examenParaModificar = null;
    },

    examenEliminado(examen) {
      this.cerrarEliminarExamen();
      this.$emit("examenEliminado", examen);
    },
  },
};
</script>
