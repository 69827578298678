import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VList,[_vm._l((_vm.examenes),function(examen){return _c(VListItem,{key:examen._id},[_c(VListItemAvatar,[_c(VAvatar,{attrs:{"color":"primary"}},[_c(VIcon,{attrs:{"dark":""}},[_vm._v("mdi-text-box-check")])],1)],1),_c(VListItemContent,[_c(VListItemTitle,[_c('router-link',{staticClass:"link",attrs:{"to":("examenes/" + (examen._id))}},[_vm._v(_vm._s(examen.nombre)+" ")])],1),_c(VListItemSubtitle,[_vm._v("Creado: "+_vm._s(_vm.getLocaleString(examen.creador.fecha)))])],1),(_vm.esAdmin)?_c(VListItemAction,[_c(VMenu,{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c(VList,[_vm._l((_vm.menuExamen),function(menuExamenItem,index){return _c(VListItem,{key:index,on:{"click":function($event){return _vm.menuAction(Object.assign({}, menuExamenItem, {id: examen._id}))}}},[_c(VListItemTitle,[_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(_vm._s(menuExamenItem.icon))]),_c('span',[_vm._v(_vm._s(menuExamenItem.text))])],1)],1)}),(_vm.app === 'campus')?_c(VListItem,{on:{"click":function($event){return _vm.mostrarEliminacionExamen(examen)}}},[_c(VListItemTitle,[_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-delete")]),_c('span',[_vm._v("Eliminar examen")])],1)],1):_vm._e()],2)],1)],1):_vm._e()],1)}),(_vm.mostrarCreacionExamen)?_c('crear-examen-dialog',{attrs:{"mostrar":_vm.mostrarCreacionExamen,"examen":_vm.examenParaModificar},on:{"cancelar":_vm.cerrarCreacionExamen,"examenGuardado":_vm.examenGuardado}}):_vm._e(),(_vm.mostrarMoverArea)?_c('mover-area-dialog',{attrs:{"mostrar":_vm.mostrarMoverArea,"areas":_vm.areas,"examen":_vm.examenParaModificar},on:{"cancelar":_vm.cerrarMoverArea,"examenMovido":_vm.examenMovido}}):_vm._e(),(_vm.mostrarClonarExamen)?_c('clonar-examen-dialog',{attrs:{"mostrar":_vm.mostrarClonarExamen,"areas":_vm.areas,"examen":_vm.examenParaModificar},on:{"cancelar":_vm.cerrarClonarExamen,"examenClonado":_vm.examenClonado}}):_vm._e(),(_vm.mostrarEliminarExamen)?_c('eliminar-examen-dialog',{attrs:{"mostrar":_vm.mostrarEliminarExamen,"idExamen":_vm.examenParaModificar._id},on:{"cancelar":function($event){return _vm.cerrarEliminarExamen()},"examenEliminado":_vm.examenEliminado}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }